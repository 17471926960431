<template>
    <div class="profile">
        <template class="mt-0">
            <advertisement-top-banner-text />
        </template>
        <div class="container">
            <b-row>
                <b-col md="6">
                    <template v-if="$options.components['profile-show-edit-profile']">
                        <profile-show-edit-profile />
                    </template>
                </b-col>
                
                <b-col md="6">
                    <div>
                        <div class="walletBalance mb-3 mt-3">
                            <b-card no-body class="text-center">
                                <b-card-text class="textWalletBalance mt-2 mb-0">
                                    Your Balance
                                </b-card-text>
                                <b-card-text class="amountWalletBalance mb-2">
                                    &#x20B9; {{ walletData.wallet.balance }}</b-card-text>
                            </b-card>
                        </div>
                        <div class="walletHistory mb-1">
                            <h4 class="heading">Transaction History</h4>
                        </div>
                        <span>
                            <b-table class="text-center" head-variant="light" outlined :fields="fields"
                                :items="walletHistory">
                                <template class="textWalletBalance mt-2 mb-0" #cell(amount)="data">
                                    <b>&#x20B9;{{ data.value }}
                                        <b-icon class="ml-5" icon="chevron-right" aria-label="Help"></b-icon>
                                    </b>
                                </template>
                            </b-table>
                        </span>
                        <div class="historyNotAvailable text-center" v-show="walletData.history.length === 0">
                            <h6>History not Available</h6>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
    name: "wallet",
    components: {},
    data() {
        return {
            fields: [
                {
                    key: "transRefNo",
                    label: "Id"
                },
                {
                    key: "action",
                    label: "Type",
                    formatter: "transactionType"
                },
                {
                    key: "createdAt",
                    label: "Date",
                    formatter: "dateFormat"
                },
                {
                    key: "amount",
                    label: "Amount"
                },
            ],
        };
    },
    async mounted() {
        this.isLoading = true;
        await this.$store.dispatch("profile/fetchWalletData", {
            startDate: moment(this.userInfo.createdAt).format("YYYY-MM-DD"),
            // endDate: moment(this.userInfo.createdAt).add(365, 'd').format('YYYY-MM-DD')
            endDate: moment().format('YYYY-MM-DD')
        });
        this.isLoading = false;
    },
    computed: {
        ...mapState({
            walletData: (state) => state.profile.walletData,
            userInfo: (state) => state.auth.userInfo,
            walletHistory(state) {
                let walletHistoryList = []
                if (
                    Array.isArray(state.profile.walletData.history) &&
                    state.profile.walletData.history.length > 0
                ) {
                    state.profile.walletData.history.map((obj) => {
                        let arrayObj = {
                            transRefNo: obj.transRefNo,
                            action: obj.action,
                            createdAt: obj.createdAt,
                            amount: obj.amount,
                        };
                        walletHistoryList.push(arrayObj);
                    });
                    return walletHistoryList;
                } else {
                    this.isloading = false;
                    return [];
                }
            },
        }),
    },
    methods: {
        transactionType(value) {
            return value === "C" ? value = "Credit" : value = "Debit"
        },
        dateFormat() {
            return moment(this.walletHistory.createdAt).format("DD-MMM-YYYY");
        },
    },
};
</script>

<style scoped>
.walletBalance {
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #e2e2e2;
    box-shadow: 0px 0px 4px rgb(210 210 210);
}

.textWalletBalance {
    color: #284397;
    font-size: x-large;
    font-weight: 500;
    text-align: center;
}

.amountWalletBalance {
    color: #284397;
    font-size: xx-large;
    font-weight: 700;
}

.walletHistory {}

.heading {
    font-size: larger;
    font-weight: 600;
}

.historyNotAvailable {
    height: 12vh;
}
</style>
