var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "profile"
  }, [[_c('advertisement-top-banner-text')], _c('div', {
    staticClass: "container"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_vm.$options.components['profile-show-edit-profile'] ? [_c('profile-show-edit-profile')] : _vm._e()], 2), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('div', [_c('div', {
    staticClass: "walletBalance mb-3 mt-3"
  }, [_c('b-card', {
    staticClass: "text-center",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-text', {
    staticClass: "textWalletBalance mt-2 mb-0"
  }, [_vm._v(" Your Balance ")]), _c('b-card-text', {
    staticClass: "amountWalletBalance mb-2"
  }, [_vm._v(" ₹ " + _vm._s(_vm.walletData.wallet.balance))])], 1)], 1), _c('div', {
    staticClass: "walletHistory mb-1"
  }, [_c('h4', {
    staticClass: "heading"
  }, [_vm._v("Transaction History")])]), _c('span', [_c('b-table', {
    staticClass: "text-center",
    attrs: {
      "head-variant": "light",
      "outlined": "",
      "fields": _vm.fields,
      "items": _vm.walletHistory
    },
    scopedSlots: _vm._u([{
      key: "cell(amount)",
      fn: function fn(data) {
        return [_c('b', [_vm._v("₹" + _vm._s(data.value) + " "), _c('b-icon', {
          staticClass: "ml-5",
          attrs: {
            "icon": "chevron-right",
            "aria-label": "Help"
          }
        })], 1)];
      }
    }])
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.walletData.history.length === 0,
      expression: "walletData.history.length === 0"
    }],
    staticClass: "historyNotAvailable text-center"
  }, [_c('h6', [_vm._v("History not Available")])])])])], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }